import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import PageContent from "../components/PageContent";

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title="404: Not Found" />
        <PageContent>
          <h1>Not Found</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </PageContent>
      </Layout>
    )
  }
}

export default NotFoundPage
